<template>
    <div id="app" class="min-h-screen bg-gray-100 flex items-center justify-center">
      <div class="bg-white p-6 rounded shadow-md w-96">
        <h1 class="text-2xl font-semibold mb-4">Inventory Management</h1>
        <form @submit.prevent="addItem">
          <div class="mb-2 flex items-center">
            <input v-model="newItem.name" type="text" class="w-2/3 p-2 border" placeholder="Item Name" />
            <input v-model.number="newItem.quantity" type="number" class="w-1/3 p-2 ml-2 border" placeholder="Quantity" />
          </div>
          <div class="mb-2">
            <label for="photo" class="block text-sm font-medium text-gray-700">Upload Photo</label>
            <input
              type="file"
              id="photo"
              accept="image/*"
              @change="handleFileUpload"
              class="mt-1 block w-full p-2 border"
            />
          </div>
          <button type="submit" class="w-full bg-blue-500 text-white p-2 rounded">Add Item</button>
        </form>
        <ul class="mt-4">
          <li v-for="(item, index) in items" :key="index" class="flex justify-between items-center border-b p-2">
            <div>
              <span class="font-semibold">{{ item.name }}</span>
              <span class="text-gray-500 ml-2">Quantity: {{ item.quantity }}</span>
              <span class="text-gray-500 ml-2">Added: {{ formatDate(item.timestamp) }}</span>
            </div>
            <div>
              <img v-if="item.photo" :src="item.photo" alt="Item Photo" class="max-h-10 max-w-10 mr-2" />
              <button @click="editItem(index)" class="text-blue-500 mr-2">Edit</button>
              <button @click="deleteItem(index)" class="text-red-500">Delete</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        newItem: {
          name: '',
          quantity: 0,
          timestamp: null,
          photo: null,
        },
        items: [
          { name: 'Product 1', quantity: 10, timestamp: new Date(), photo: null },
          { name: 'Product 2', quantity: 5, timestamp: new Date(), photo: null },
          { name: 'Product 3', quantity: 20, timestamp: new Date(), photo: null },
        ],
      };
    },
    methods: {
      addItem() {
        if (this.newItem.name.trim() !== '' && this.newItem.quantity > 0) {
          this.newItem.timestamp = new Date();
          this.items.push({ ...this.newItem });
          this.newItem.name = '';
          this.newItem.quantity = 0;
          this.newItem.timestamp = null;
          this.newItem.photo = null;
        }
      },
      editItem(index) {
    const updatedName = prompt('Edit item name:', this.items[index].name);
    const updatedQuantity = prompt('Edit quantity:', this.items[index].quantity);
    if (updatedName !== null && updatedQuantity !== null) {
        this.items[index].name = updatedName;
        this.items[index].quantity = parseInt(updatedQuantity, 10);
        // No need for this.$set in Vue 3
    }
    },


      deleteItem(index) {
        if (confirm('Are you sure you want to delete this item?')) {
          this.items.splice(index, 1);
        }
      },
      handleFileUpload(event) {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            this.newItem.photo = reader.result;
          };
          reader.readAsDataURL(file);
        }
      },
      formatDate(timestamp) {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        return new Date(timestamp).toLocaleDateString('en-US', options);
      },
    },
  };
  </script>
  
  <style>
 #app {
  background-image: url('../assets/inventory.jpg');
  
}
  </style>
  