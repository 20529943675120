
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDEk5D_taaiF2RJy6H41Sl-gTEudo2w-u4",
  authDomain: "todo-a0f86.firebaseapp.com",
  projectId: "todo-a0f86",
  storageBucket: "todo-a0f86.appspot.com",
  messagingSenderId: "927705990660",
  appId: "1:927705990660:web:1765c70b64728531529751"
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export default db;

