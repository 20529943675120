<template>
    <!-- Todo App -->
    <div class="main">
        <div class="container">
            <div class="title">
                <h1 class="text-white-700 text-5xl mb-14">Todo App</h1>
            </div>
            <div class="actions">
                <form @submit.prevent="addTask">
                    <input type="text" placeholder="Add Task" v-model="task" required />
                    <button class="mx-3 bg-blue-700" type="submit">Add</button>
                    <input type="text" placeholder="Search task" v-model="searchQuery" />
                </form>
            </div>
            <div class="tasks">
                <div class="task-items" v-for="todo in filteredTodos" :key="todo.id">
                    <p :class="{ 'line-through': todo.done }" class="mx-4">{{ todo.details }}</p>
                    <button class="done-btn mx-4" @click="markAsDone(todo.id)">Done</button>
                    <button class="remove-btn" @click="removeTask(todo.id)">Remove</button>
                </div>
                <button class="clear-btn" @click="clearAllTasks">Clear ALL tasks</button>
            </div>
        </div>
    </div>
</template>
<script setup>
import database from '../firebase';
import { ref, computed, onMounted } from 'vue';
import { collection, addDoc, getDocs, deleteDoc, updateDoc, doc } from 'firebase/firestore';

    const db = database;
    const task = ref('');
    const searchQuery = ref('');
    const todos = ref([]);

    // Function to fetch tasks from Firestore
    const fetchTasks = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, 'tasks'));
        todos.value = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    } catch (error) {
        console.error('Error fetching tasks:', error);
    }
    };

    // Fetch tasks on component mount
    onMounted(fetchTasks);

    const addTask = async () => {
    try {
        if (task.value.trim() !== '') {
            const newTask = { details: task.value, done: false };
            const docRef = await addDoc(collection(db, 'tasks'), newTask);
            todos.value.push({ ...newTask, id: docRef.id });
            task.value = '';
        }
    } catch (error) {
        console.error('Error adding task:', error);
    }
    };

    const removeTask = async (taskId) => {
    try {
        await deleteDoc(doc(db, 'tasks', taskId));
        todos.value = todos.value.filter((todo) => todo.id !== taskId);
    } catch (error) {
        console.error('Error removing task:', error);
    }
    };

    const markAsDone = async (taskId) => {
    try {
        const todo = todos.value.find((t) => t.id === taskId);
        if (todo) {
            await updateDoc(doc(db, 'tasks', taskId), { done: !todo.done });
            todo.done = !todo.done;
        }
    } catch (error) {
        console.error('Error updating task:', error);
    }
    };

    const clearAllTasks = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, 'tasks'));
        querySnapshot.forEach(async (doc) => {
            await deleteDoc(doc.ref);
        });
        todos.value = [];
    } catch (error) {
        console.error('Error clearing all tasks:', error);
    }
    };

    const filteredTodos = computed(() => {
    return todos.value.filter((todo) =>
        todo.details.toLowerCase().includes(searchQuery.value.toLowerCase())
    );
    });
</script>

<style scoped>
.main {
  background-image: url('../assets/bg.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0; /* Remove default margin to ensure full-width background */
}

.container {
  background-color: rgba(170, 64, 138, 0.8);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  max-width: 600px; /* Set a maximum width for the container */
  width: 100%; /* Ensure the container is not wider than its parent */
}

.title {
  margin-bottom: 20px;
}

.actions {
  margin-bottom: 20px;
}

input[type="text"] {
  padding: 8px;
  margin-right: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

button.done-btn {
  background-color: #0dff00;
}

button.remove-btn {
  background-color: #f44336;
}

button.clear-btn {
  background-color: blue;
}

.tasks {
  text-align: center;
}

.task-items {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 8px;
}

.line-through {
  text-decoration: line-through;
}
</style>


