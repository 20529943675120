<template>
    <footer class="bg-gray-50">
        <div class="bg-white pt-4 sm:pt-10 lg:pt-12">
  <footer class="mx-auto max-w-screen-2xl px-4 md:px-8">
    <div class="flex flex-col items-center border-t pt-6">
      <!-- nav - start -->
      <nav class="mb-4 flex flex-wrap justify-center gap-x-4 gap-y-2 md:justify-start md:gap-6">
        <a href="/about" class="text-gray-500 transition duration-100 hover:text-indigo-500 active:text-indigo-600">About</a>
        <a href="/projects" class="text-gray-500 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Projects</a>
        <a href="/contact" class="text-gray-500 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Contact</a>
        
      </nav>
      <!-- nav - end -->

    </div>

    <div class="py-8 text-center text-sm text-gray-400">© 2023 -  Joshua Blase. All rights reserved.</div>
  </footer>
</div>
    </footer>
</template>